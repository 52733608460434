import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import { colorsV2 } from '../../colors-v2';

const Container = styled.div`
  padding: 10px 12px;
  background-color: ${colorsV2.white100};
  cursor: pointer;

  &:hover:not(.disabled) {
    background-color: ${colorsV2.gray10};
  }

  &.divider {
    border-bottom: 1px solid ${colorsV2.gray20};
  }

  &.selected {
    background-color: ${colorsV2.blue10};
  }

  &.disabled {
    cursor: initial;
  }
`;

interface DropdownItemItemLayoutProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'content'> {
  disabled?: boolean;
  selected?: boolean;
  divider?: boolean;
  content?: React.ReactNode;
}

export const DropdownItemItemLayout = (props: DropdownItemItemLayoutProps) => {
  const {
    className,
    selected = false,
    divider = false,
    disabled = false,
    content,
    ...rest
  } = props;

  return (
    <Container
      className={cl('dropdown-item-item-layout', className, { selected, divider, disabled })}
      {...rest}
    >
      {content}
    </Container>
  );
};
